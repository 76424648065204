import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import { addNewUser } from "../redux/actions/userAuth";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";

const currencies = [
  {
    value: "user",
    label: "User",
  },
  {
    value: "admin",
    label: "Admin",
  },
];

const AddNewUser = () => {
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const [name, setname] = useState("");
  const [mobile, setmobile] = useState("");
  const [email, setemail] = useState("");
  const [address, setaddress] = useState("");
  const [pin, setpin] = useState("");
  const [city, setcity] = useState("");
  const [country, setcountry] = useState("");
  const [role, setrole] = useState("user");
  const [showPassword, setShowPassword] = useState(false);
  const [adhar, setAdhar] = useState("");
  const [rationCard, setRationCard] = useState("");
  const oldPassword = () => setShowPassword((show) => !show);
  const [click, setClick] = useState(false);
  // const [image , setimage] = useState('');
  const dispatch = useDispatch();
  const status = useSelector((state) => state);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      oldPassword: "",
      email: "",
      phone: "",
      city: "",
      country: "",
      pincode: "",
      role: "",
      address: "",
      aadharno: "",
    },
  });
  if (errors === null) {
    setLoader(false);
  }
  console.log("status------", status);
  const onSubmit = (data) => {
    setLoader(true);
    let apiData = {
      name: name,
      email: email,
      password: data.oldPassword,
      phone: mobile,
      city: city,
      country: country,
      pincode: parseInt(pin),
      role: role,
      address: address,
      aadharcard: parseInt(adhar),
      ration_card: rationCard,
    };
    dispatch(addNewUser(apiData));
    console.log("Submitted Data:", apiData);
  };
  const clickFun = () => {
    if (!click) {
      setClick(true);
    } else {
      setClick(false);
    }
  };

  useEffect(() => {
    if (status.addNewUser?.data === "") {
      if (status.addNewUser?.successData?.status === 201) {
        setTimeout(() => {
          let path = `/dashboard/AllUsers`;
          navigate(path);
        }, 2500);
        toast.success("User add succesfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
    if (status.addNewUser?.errorData?.response?.data?.statusCode === 400) {
      toast.error(status.addNewUser?.errorData?.response?.data?.messageToUser, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [status]);
  useEffect(() => {
    if (errors?.email?.type === "required") {
      toast.error("Please enter email address", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }

    if (errors?.email?.type === "pattern") {
      toast.error("Please enter valid email address", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [click]);
  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            All Users
            <span>
              <img alt="img" src="../image/users.svg"></img> / All Users
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap inputwrap">
          <div className="tablewrappadd">
            <div className="bothwrap">
              <div className="adduserhadd">
                <h2>Add New User</h2>
                <p>Create an account </p>
              </div>
              <div className="mainbtn">
                <Link to="/dashboard/ImportBulkUser" className="bulkBTn">
                  Import Bulk Users
                </Link>
                <Link to="../download/import-users.csv" className="csvFile">
                  Download CSV format
                </Link>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="adduserwrap">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/profile.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="full Name"
                    multiline
                    maxRows={4}
                    variant="filled"
                    name="name"
                    onChange={(e) => setname(e.target.value)}
                  />
                </div>
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../image/phone.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Mobile Number"
                    maxRows={4}
                    type="number"
                    variant="filled"
                    name="mobile"
                    onChange={(e) => setmobile(e.target.value)}
                  />
                </div>
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../image/mail.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Email Address"
                    {...register("email", {
                      required: "required",
                      pattern: /\S+@\S+\.\S+/,
                    })}
                    maxRows={4}
                    variant="filled"
                    name="email"
                    onChange={(e) => setemail(e.target.value)}
                  />
                </div>
              </div>
              <div className="adduserwrap">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/lock.svg"></img>
                  </span>
                  <FormControl variant="filled">
                    <InputLabel htmlFor="filled-adornment">
                      Current Password
                    </InputLabel>
                    <FilledInput
                      id="filled-adornment-password"
                      type={showPassword ? "text" : "password"}
                      name="oldPassword"
                      {...register("oldPassword", { required: "required" })}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={oldPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <img alt="img" src="../image/eye.svg"></img>
                            ) : (
                              <img alt="img" src="../image/eye2.svg"></img>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {/* {errors.oldPassword &&
                    errors.oldPassword.type === "required" && (
                      <label className="error-message">
                        This is required field
                      </label>
                    )} */}
                </div>

                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/location.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Address"
                    multiline
                    maxRows={4}
                    variant="filled"
                    name="address"
                    onChange={(e) => setaddress(e.target.value)}
                  />
                </div>
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/location.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="City"
                    multiline
                    maxRows={4}
                    variant="filled"
                    name="city"
                    onChange={(e) => setcity(e.target.value)}
                  />
                </div>
              </div>
              <div className="adduserwrap">
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../image/location.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Pin Code"
                    type="number"
                    maxRows={4}
                    variant="filled"
                    name="pincode"
                    onChange={(e) => setpin(e.target.value)}
                  />
                </div>

                <div className="customfield">
                  <span className="pageimage">
                    {" "}
                    <img alt="img" src="../image/flag.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Country"
                    multiline
                    maxRows={4}
                    variant="filled"
                    name="Country"
                    onChange={(e) => setcountry(e.target.value)}
                  />
                </div>
                <div className="selectfield pro1">
                  <div className="customfield">
                    <span className="pageimage">
                      <img alt="img" src="../image/users.svg"></img>
                    </span>
                    <Box component="form" noValidate autoComplete="off">
                      <div>
                        <TextField
                          id="filled-select-currency"
                          select
                          label="Select User Role"
                          defaultValue="user"
                          variant="filled"
                          name="role"
                          onChange={(e) => setrole(e.target.value)}
                        >
                          {currencies.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </Box>
                    <img
                      alt="img"
                      src="../image/arrowb.svg"
                      className="arrowb"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="adduserwrap">
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../image/profile.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Aadhar Card No."
                    type="number"
                    maxRows={4}
                    variant="filled"
                    name="aadharno"
                    onChange={(e) => setAdhar(e.target.value)}
                  />
                </div>
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../image/profile.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Ration Card"
                    type="text"
                    maxRows={4}
                    variant="filled"
                    name="rationCard"
                    onChange={(e) => setRationCard(e.target.value)}
                  />
                </div>
              </div>
              {/* <h4 className="proof">Proof of identity</h4>
                <div className="adduserwrap">
                <div className="customfield">
                <span className="pageimage"><img alt="img" src="../image/location.svg"></img></span>
                <TextField
                        id="filled-multiline-flexible"
                        label="Aadhaar Number"
                        multiline
                        maxRows={4}
                        variant="filled"
                        name="aadhaar"
                        onChange={(e) => setaadhaar(e.target.value)}
                        />
                </div>
                <div className="customfield">
               <span className="pageimage">
                <img alt="img" 
                src="../image/upload.svg"
                name="image"
                onChange={(e) => setimage(e.target.value)}>
                </img></span>
                <label htmlFor="upload-photo" className="chosefole">
                <input
                  style={{ display: 'none' }}
                  id="upload-photo"
                  name="upload-photo"
                  type="file"
                />

                <Button color="secondary" variant="contained" component="span" id="choose">
                Choose File
                </Button>
                <span>Supported Files JPG, PDF, PNG</span>
              </label>
                </div>
                </div> */}
              <div className="mainbtn">
                <button className="save" type="submit" onClick={clickFun}>
                  Create
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddNewUser;
