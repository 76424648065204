import React, { useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import { Link, useNavigate } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.css";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  energy,
  allUser,
  allDevice,
  userAndDevices,
  projectList,
} from "../redux/actions/userAuth";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
const battery = [
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 70,
    label: "70",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 90,
    label: "90",
  },
  {
    value: 100,
    label: "100",
  },
];
const Energy = () => {
  const status = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [energydata, setEnergyData] = useState([]);
  const [allUserList, setallUserList] = useState([]);
  const [allDevicedata, setAllDevicedata] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [projectLst, setProjectLst] = useState();
  const [userDevice, setUserDevice] = useState();
  const [userDevice1, setUserDevice1] = useState();
  const [userDevice2, setUserDevice2] = useState();
  const [data, setData] = useState({
    userId: "",
    deviceId: "",
    startDate: "",
    endDate: "",
    address: "",
    project: "",
    stateName: "",
    battery: "",
  });
  // energy data
  useEffect(() => {
    dispatch(
      energy(
        data.startDate,
        data.endDate,
        data.userId,
        data.deviceId,
        data.address,
        data.project,
        data.stateName,
        data.battery
      )
    );
  }, []);
  useEffect(() => {
    dispatch(userAndDevices());
  }, []);

  useEffect(() => {
    if (status.energy?.data !== "") {
      if (status.energy?.data.status === 200) {
        setEnergyData(status.energy?.data?.data);
      }
      if (status.energy?.data?.response != null) {
        if (status.energy?.data?.response?.status === 401) {
          toast.error(status.energy?.data?.response?.data?.error, {
            position: "bottom-right",
            theme: "colored",
          });
          window.sessionStorage.clear();
          let path = `/`;
          navigate(path);
          window.location.reload();
        }
      }
    }
  }, [status]);
  useEffect(() => {
    if (status.userAndDevices?.successData?.data !== "") {
      if (status.userAndDevices?.successData?.status === 200) {
        const uniqueIdsMap = status.userAndDevices?.successData?.data.reduce(
          (map, obj) => {
            map.set(obj?.user_id, obj?.name, obj?.device_id);
            return map;
          },
          new Map()
        );
        const uniqueObjects = Array.from(
          uniqueIdsMap,
          ([user_id, name, device_id]) => ({
            user_id,
            name,
            device_id,
          })
        );
        setUserDevice(uniqueObjects);

        const uniqueIdsMap1 = status.userAndDevices?.successData?.data.reduce(
          (map, obj) => {
            map.set(obj?.device_id);
            return map;
          },
          new Map()
        );
        const uniqueObjects1 = Array.from(uniqueIdsMap1, ([device_id]) => ({
          device_id,
        }));
        setUserDevice1(uniqueObjects1);

        const uniqueIdsMap2 = status.userAndDevices?.successData?.data.reduce(
          (map, obj) => {
            map.set(obj?.district);
            return map;
          },
          new Map()
        );
        const uniqueObjects2 = Array.from(uniqueIdsMap2, ([district]) => ({
          district,
        }));
        setUserDevice2(uniqueObjects2);
      }
    }
  }, [status]);
  useEffect(() => {
    const apiData = {};
    dispatch(allUser(apiData));
  }, []);
  useEffect(() => {
    const apiData = {};
    dispatch(allDevice(apiData));
  }, []);
  useEffect(() => {
    if (status.allDevice?.data !== "") {
      if (status.allDevice?.data.status === 200) {
        setAllDevicedata(status.allDevice?.data?.data);
      }
    }
  }, [status]);
  useEffect(() => {
    if (status.allUser.data !== "") {
      if (status.allUser.data.status === 200) {
        setallUserList(status.allUser.data.data);
      }
    }
  }, [status]);
  const filterUserData1 = (e) => {
    const apiData = parseInt(e.target.value);
    //dispatch(filterUserData(apiData));
    setData({
      ...data,
      userId: e.target.value,
    });
    dispatch(
      energy(
        data.startDate,
        data.endDate,
        e.target.value,
        data.deviceId,
        data.address,
        data.project,
        data.stateName,
        data.battery
      )
    );
  };
  useEffect(() => {
    if (status.filterUserData?.successData?.data !== "") {
      if (status.filterUserData?.successData?.status === 200) {
        //setEnergyData(status.filterUserData?.successData?.data);
      }
    }
  }, [status]);
  const filterDevice1 = (e) => {
    const apiData = parseInt(e.target.value);
    //dispatch(filterDeviceData(apiData));
    setData({
      ...data,
      deviceId: e.target.value,
    });
    dispatch(
      energy(
        data.startDate,
        data.endDate,
        data.userId,
        e.target.value,
        data.address,
        data.project,
        data.stateName,
        data.battery
      )
    );
  };
  const filterProject = (e) => {
    setData({
      ...data,
      project: e.target.value,
    });
    dispatch(
      energy(
        data.startDate,
        data.endDate,
        data.userId,
        data.deviceId,
        data.address,
        e.target.value,
        data.stateName,
        data.battery
      )
    );
  };
  const addessChange = (e) => {
    setData({
      ...data,
      address: e.target.value,
    });
    dispatch(
      energy(
        data.startDate,
        data.endDate,
        data.userId,
        data.deviceId,
        e.target.value,
        data.project,
        data.stateName,
        data.battery
      )
    );
  };
  const stateChange = (e) => {
    setData({
      ...data,
      stateName: e.target.value,
    });
    dispatch(
      energy(
        data.startDate,
        data.endDate,
        data.userId,
        data.deviceId,
        data.address,
        data.project,
        e.target.value,
        data.battery
      )
    );
  };
  const batteryChange = (e) => {
    setData({
      ...data,
      battery: e.target.value,
    });
    dispatch(
      energy(
        data.startDate,
        data.endDate,
        data.userId,
        data.deviceId,
        data.address,
        data.project,
        data.stateName,
        e.target.value
      )
    );
  };

  const getDataRange = (e) => {
    // const startDate = e[0];
    // const endDate = e[1];
    // const formattedDate = startDate.toLocaleDateString();
    // const [day, month, year] = formattedDate.split("/");
    // const startDateNew = `${year}-${month}-${day}`;
    // const formattedDate1 = endDate.toLocaleDateString();
    // const [day1, month1, year1] = formattedDate1.split("/");
    // const endDateNew = `${year1}-${month1}-${day1}`;
    const startDate = moment(e[0]);
    const endDate = moment(e[1]);

    const startDateNew = startDate.format("YYYY-MM-DD");
    const endDateNew = endDate.format("YYYY-MM-DD");
    setData({
      ...data,
      startDate: startDateNew,
      endDate: endDateNew,
    });
    dispatch(
      energy(
        startDateNew,
        endDateNew,
        data.userId,
        data.deviceId,
        data.address,
        data.project,
        data.stateName,
        data.battery
      )
    );
  };
  const clearDateRange = () => {
    setData({
      ...data,
      startDate: "",
      endDate: "",
    });
    dispatch(
      energy(
        "",
        "",
        data.userId,
        data.deviceId,
        data.address,
        data.project,
        data.stateName,
        data.battery
      )
    );
  };
  useEffect(() => {
    if (status.filterByDate?.successData?.data !== "") {
      if (status.filterByDate?.successData?.status === 200) {
        setFilteredData(status.filterByDate?.successData?.data);
      }
    }
  }, [status]);
  useEffect(() => {
    dispatch(projectList());
  }, []);
  useEffect(() => {
    if (status.projectList?.successData?.data !== "") {
      if (status.projectList?.successData?.status === 200) {
        setProjectLst(status?.projectList?.successData?.data);
      }
    }
  }, [status]);
  const parmsDataSet = (data1, data2, data3) => {
    window.sessionStorage.setItem("eParmsName", data1);
    window.sessionStorage.setItem("eParmsDeviceId", data2);
    window.sessionStorage.setItem("eParmsDeviceRef", data3);
  };
  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            Cookstove Usage
            <span>
              <img alt="" src="../image/leaf.svg"></img> / Cookstove Usage
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap">
          <div className="tablewrappadd">
            <h2>Cookstove Usage Summary</h2>
            <p>Recently Device Usages</p>
          </div>
          <div className="userFilter">
            <div className="adduserwrap">
              <div className="selectfield">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/users.svg"></img>
                  </span>
                  <Box component="form" noValidate autoComplete="off">
                    <div>
                      <TextField
                        id="filled-select-currency"
                        select
                        label="Select User"
                        variant="filled"
                        name="userId"
                        onChange={(e) => filterUserData1(e)}
                      >
                        <MenuItem value={""}>Select User</MenuItem>
                        {userDevice?.map((option) => (
                          <MenuItem key={option.user_id} value={option.user_id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Box>
                  <img
                    alt="img"
                    src="../image/arrowb.svg"
                    className="arrowb"
                  ></img>
                </div>
              </div>
              <div className="selectfield">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/dish.svg"></img>
                  </span>
                  <Box component="form" noValidate autoComplete="off">
                    <div>
                      <TextField
                        id="filled-select-currency"
                        select
                        label="Select Device"
                        variant="filled"
                        name="deviceId"
                        onChange={(e) => filterDevice1(e)}
                      >
                        <MenuItem value={""}>Select Device Id</MenuItem>
                        {userDevice1?.map((option) => (
                          <MenuItem
                            key={option.device_id}
                            value={option.device_id}
                          >
                            {option.device_id}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Box>
                  <img
                    alt="img"
                    src="../image/arrowb.svg"
                    className="arrowb"
                  ></img>
                </div>
              </div>
              <div className="selectfield">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/project.svg"></img>
                  </span>
                  <Box component="form" noValidate autoComplete="off">
                    <div>
                      <TextField
                        id="filled-select-currency"
                        select
                        label="Select Project Name"
                        variant="filled"
                        name="ProjectName"
                        onChange={(e) => filterProject(e)}
                      >
                        <MenuItem value={""}>Select Project Name</MenuItem>
                        {projectLst?.map((option) => (
                          <MenuItem
                            key={option?.projectId}
                            value={option?.projectName}
                          >
                            {option?.projectName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Box>
                  <img
                    alt="img"
                    src="../image/arrowb.svg"
                    className="arrowb"
                  ></img>
                </div>
              </div>

              <div className="customfield datepick mb-20">
                <span className="pageimage">
                  <img alt="img" src="../image/calender1.svg"></img>
                </span>
                <DateRangePicker
                  placement="auto"
                  placeholder="Select Date"
                  onOk={(e) => getDataRange(e)}
                  onClean={() => clearDateRange()}
                />
              </div>
              <div className="selectfield">
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../image/location.svg"></img>
                  </span>
                  <Box component="form" noValidate autoComplete="off">
                    <div>
                      <TextField
                        id="filled-multiline-flexible"
                        label="Enter district"
                        maxRows={4}
                        variant="filled"
                        name="name"
                        onChange={(e) => addessChange(e)}
                      />
                    </div>
                  </Box>
                </div>
              </div>
              <div className="selectfield">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/location.svg"></img>
                  </span>
                  <Box component="form" noValidate autoComplete="off">
                    <div>
                      <TextField
                        id="filled-select-currency"
                        select
                        label="Select State"
                        variant="filled"
                        name="stateName"
                        onChange={(e) => stateChange(e)}
                      >
                        <MenuItem value={""}>Select State</MenuItem>
                        {userDevice2?.map((option, i) => (
                          <MenuItem key={i} value={option?.district}>
                            {option?.district}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Box>
                  <img
                    alt="img"
                    src="../image/arrowb.svg"
                    className="arrowb"
                  ></img>
                </div>
              </div>
              <div className="selectfield">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/battery.svg"></img>
                  </span>
                  <Box component="form" noValidate autoComplete="off">
                    <div>
                      <TextField
                        id="filled-select-currency"
                        select
                        label="Select Battery (%)"
                        variant="filled"
                        onChange={(e) => batteryChange(e)}
                      >
                        <MenuItem value={""}>Select Battery (%)</MenuItem>
                        {battery?.map((option, i) => (
                          <MenuItem key={i} value={option?.value}>
                            {option?.label}%
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Box>
                  <img
                    alt="img"
                    src="../image/arrowb.svg"
                    className="arrowb"
                  ></img>
                </div>
              </div>
              <div className="download-excel">
                <DownloadTableExcel
                  filename="Cookstove Usage Summary"
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <button>
                    <FileDownloadIcon style={{ color: "#fff" }} /> Export Excel{" "}
                  </button>
                </DownloadTableExcel>
              </div>
            </div>
          </div>
          <div className="customtabel">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                ref={tableRef}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>User Name</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Last Used</TableCell>
                    <TableCell>Total No. of Usages</TableCell>
                    <TableCell>Total Duration</TableCell>
                    <TableCell>Battery (%)</TableCell>
                    <TableCell>State</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {energydata?.map((energydata, i) => {
                    const originalDate = energydata?.start_date;
                    const trimDate = moment(originalDate)
                      .toISOString()
                      .slice(0, -1);
                    const formattedDate =
                      moment(trimDate).format("MMM DD, YYYY");
                    var originalDate1 = moment(energydata.start_date);
                    const trimTime1 = moment(originalDate1)
                      .toISOString()
                      .slice(0, -1);
                    var formattedTime = moment(trimTime1).format("hh:mm A");
                    var originalDate2 = moment(energydata.end_date);
                    const trimTime2 = moment(originalDate2)
                      .toISOString()
                      .slice(0, -1);
                    var formattedTime2 = moment(trimTime2).format("hh:mm A");

                    const totalSeconds = energydata?.total_duration_hours;
                    const duration = moment.duration(totalSeconds, "seconds");
                    const hours = Math.floor(duration.asHours());
                    const minutes = duration.minutes();
                    const seconds = duration.seconds();
                    return (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <div className="tfirst">
                            <div className="tfleft tfleft2">
                              <span className="pageimage">
                                <img alt="" src="../image/profile.svg"></img>
                              </span>
                            </div>
                            <div className="tfright tfright2">
                              <p className="green">
                                <Link
                                  onClick={() =>
                                    parmsDataSet(
                                      energydata?.name,
                                      energydata?.device_id,
                                      energydata?.device_ref_num
                                    )
                                  }
                                  to={`/dashboard/EnergySummary/${energydata.user_id}`}
                                >
                                  {energydata.name}
                                </Link>
                              </p>
                              <span>{energydata.email}</span>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tfright">
                            <p className="green">{energydata?.project_name}</p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tfright">
                            <p className="green">IOT {energydata.device_id}</p>
                            {/* <span>REF #{energydata.device_ref_num}</span> */}
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tfright">
                            <p>{formattedDate}</p>
                            <span>
                              {/* {formattedTime} - {formattedTime2} */}
                            </span>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tfright">
                            <p className="green">
                              <Link
                                onClick={() =>
                                  parmsDataSet(
                                    energydata?.name,
                                    energydata?.device_id,
                                    energydata?.device_ref_num
                                  )
                                }
                                to={`/dashboard/one-day-usages/${energydata?.device_id}/${energydata?.start_date}`}
                              >
                                {energydata?.no_of_usage}{" "}
                                {energydata?.no_of_usage > 1 ? "Times" : "Time"}
                              </Link>
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tfright">
                            <p>
                              {`${hours !== 0 ? hours + " hr," : ""} ${
                                minutes !== 0 ? minutes + " min," : ""
                              } ${seconds !== 0 ? seconds + " sec" : ""}`}
                            </p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tfright">
                            <p>{energydata?.battery}</p>
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="tfright">
                            <p>{energydata?.district}</p>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            {energydata === null ? (
              <div className="no-data-found">
                <img alt="img" src="../image/noDataFound.svg"></img>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Energy;
