import React from "react";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../redux/actions/userAuth";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [Email, setEmail] = useState("");
  const [click, setClick] = useState(false);
  const dispatch = useDispatch();
  const status = useSelector((state) => state);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  console.log("status", status);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { email: "", password: "" } });
  if (errors === null) {
    setLoader(false);
  }
  const onSubmit = (data) => {
    setLoader(true);
    let apiData = {
      email: data.email.trim(),
      password: data.password.trim(),
    };
    dispatch(userLogin(apiData));
  };
  const clickFun = () => {
    if (!click) {
      setClick(true);
    } else {
      setClick(false);
    }
  };
  console.log(click, "asdasdas");
  setTimeout(() => {
    setLoader(false);
  }, 5000);
  useEffect(() => {
    if (status.login.data !== "") {
      if (status.login.data.status === 200) {
        setTimeout(() => {
          let path = `/dashboard`;
          navigate(path);
          window.location.reload();
        }, 2500);
        toast.success("Success", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error("Email does not exist", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  }, [status]);
  useEffect(() => {
    {
      errors.email &&
        errors.email.type === "required" &&
        toast.error("Please enter email address", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    }
    {
      errors.email &&
        errors.email.type === "pattern" &&
        toast.error("Please enter valid email address", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    }
    {
      errors.password &&
        errors.password.type === "required" &&
        toast.error("Please enter password", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
    }
  }, [click]);

  return (
    <>
      <div className="loginpage">
        <div className="loginpagewrap">
          <div className="logo">
            <img alt="img" src="./image/logo.svg"></img>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="logintext">
              <h2>Admin Login</h2>
              <p>Cookstove usage Monitoring System</p>
              <div className="customfield customfieldlogin">
                <span className="pageimage pageimagelogin">
                  <img alt="img" src="./image/mail.svg"></img>
                </span>
                <FormControl sx={{ m: 1, width: "25ch" }} variant="filled">
                  <InputLabel htmlFor="input">Business Email</InputLabel>
                  <FilledInput
                    id="outlined-error-helper-text"
                    type="text"
                    name="email"
                    {...register("email", {
                      required: "required",
                      pattern: /\S+@\S+\.\S+/,
                    })}
                    endAdornment={
                      <InputAdornment position="end"></InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <div className="customfield customfieldlogin">
                <span className="pageimage pageimagelogin">
                  <img alt="img" src="./image/lock.svg"></img>
                </span>
                <FormControl sx={{ m: 1, width: "25ch" }} variant="filled">
                  <InputLabel htmlFor="filled-adornment-password">
                    Password
                  </InputLabel>
                  <FilledInput
                    type={showPassword ? "text" : "password"}
                    name="password"
                    {...register("password", { required: "required" })}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? (
                            <img alt="img" src="../image/eye.svg"></img>
                          ) : (
                            <img alt="img" src="../image/eye2.svg"></img>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </div>
              <h6>
                <Link to="/ForgotPassword">Recover Password?</Link>
              </h6>
              <div className="buttonholder">
                <button type="submit" onClick={clickFun}>
                  {loader && loader ? (
                    <img src="./image/loading.svg" alt="loader" />
                  ) : (
                    "Login"
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default Login;
