import React, { useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Energysummary } from "../redux/actions/userAuth";
import { useParams } from "react-router-dom";
import moment from "moment";
import { DateRangePicker } from "rsuite";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DownloadTableExcel } from "react-export-table-to-excel";
import "rsuite/dist/rsuite.css";

const EnergySummary = () => {
  const status = useSelector((state) => state);
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [summary, setSummary] = useState([]);
  console.log("summary-------", summary);
  const [data, setData] = useState({
    startDate: "",
    endDate: "",
  });
  const { userId, userName } = useParams();
  console.log("new profile id", userId);
  console.log(userId, "parms=============");
  let param = window.location.href;

  const queryString = param.split("?")[1];
  if (queryString) {
    param = queryString.split("=")[1];
  } else {
    param = "";
  }
  useEffect(() => {
    const apiParams = { userId };
    dispatch(Energysummary(apiParams, "", ""));
  }, [userId]);
  const getDataRange = (e) => {
    const startDate = moment(e[0]);
    const endDate = moment(e[1]);

    const startDateNew = startDate.format("YYYY-MM-DD");
    const endDateNew = endDate.format("YYYY-MM-DD");
    const apiParams = { userId };
    setData({
      ...data,
      startDate: startDateNew,
      endDate: endDateNew,
    });
    dispatch(Energysummary(apiParams, startDateNew, endDateNew));
  };
  const clearDateRange = () => {
    setData({
      ...data,
      startDate: "",
      endDate: "",
    });
    const apiParams = { userId };
    dispatch(Energysummary(apiParams, "", ""));
  };
  useEffect(() => {
    if (status.Energysummary.data !== "") {
      if (status.Energysummary.data.status === 200) {
        setSummary(status.Energysummary.data.data);
      }
    }
  }, [status]);
  const parmsName = window.sessionStorage.getItem("eParmsName");
  const parmsDeviceId = window.sessionStorage.getItem("eParmsDeviceId");
  const parmsDeviceRef = window.sessionStorage.getItem("eParmsDeviceRef");
  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            Cookstove Usage
            <span>
              <img alt="" src="../../image/leaf.svg"></img> / Cookstove Usage /
              Cookstove Usage Summary
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap">
          <div className="tablewrappadd filter-energy-d">
            <div className="energy-detail-header">
              <h2>{parmsName}</h2>
              <p>IOT {parmsDeviceId}</p>
            </div>
            <div className="filter-data-eng-summary">
              <div className="energy-filter">
                <div className="customfield datepick">
                  <span className="pageimage">
                    <img alt="img" src="/image/calender1.svg"></img>
                  </span>
                  <DateRangePicker
                    placement="auto"
                    placeholder="Select Date"
                    onOk={(e) => getDataRange(e)}
                    onClean={() => clearDateRange()}
                  />
                </div>
              </div>
              <div className="download-excel">
                <DownloadTableExcel
                  filename={`${parmsName}'s Device Details`}
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <button>
                    <FileDownloadIcon style={{ color: "#fff" }} /> Export Excel{" "}
                  </button>
                </DownloadTableExcel>
              </div>
            </div>
          </div>
          <div className="customtabel EnergySummary1">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                ref={tableRef}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Start time</TableCell>
                    <TableCell>End time</TableCell>
                    <TableCell>Current Temp. (°C)</TableCell>
                    <TableCell>Location</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {summary !== null &&
                    summary?.map((item, i) => {
                      const originalDate = item?.temperature_data_timestamp;
                      const trimDate = moment(originalDate)
                        .toISOString()
                        .slice(0, -1);
                      const formattedDate =
                        moment(trimDate).format("MMM DD, YYYY");
                      const formattedStart1 = item?.temperature_data_timestamp;
                      const trimTime1 = moment(formattedStart1)
                        .toISOString()
                        .slice(0, -1);
                      const formattedStart =
                        moment(trimTime1).format("hh:mm A");
                      const originalEndTime = item?.temperature_data_timestamp;
                      const trimTime2 = moment(originalEndTime)
                        .toISOString()
                        .slice(0, -1);
                      const formattedEnd = moment(trimTime2).format("hh:mm A");
                      return (
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          key={i}
                        >
                          <TableCell component="th" scope="row">
                            <div className="tfirst">
                              <div className="tfleft">{item?.image}</div>
                              <div className="tfright">
                                {/* <Link
                                  to={`/dashboard/One-Day-Usages/${parmsDeviceId}`}
                                > */}
                                <p className="green">{formattedDate}</p>
                                {/* </Link> */}
                              </div>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="tfright">
                              <p>{formattedStart}</p>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="tfright">
                              <p>{formattedEnd}</p>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="tfright">
                              <p>{item?.maximum_temperature}</p>
                            </div>
                          </TableCell>
                          <TableCell>
                            <div className="tfright">
                              <Link to="/dashboard/DevicesLocation">
                                <p>
                                  {item?.location_description}
                                  {item?.location_description ? "," : ""}{" "}
                                  {item?.latitude === 0 &&
                                  item?.longitude === 0 ? (
                                    ""
                                  ) : (
                                    <>
                                      (Lat-
                                      {item?.latitude}, Lon-{item?.longitude})
                                    </>
                                  )}
                                </p>
                              </Link>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {summary === null ? (
              <div className="no-data-found">
                <img alt="img" src="/image/noDataFound.svg"></img>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EnergySummary;
