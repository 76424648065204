export const user = {
  USER_LOGIN: "USER_LOGIN",
  USER_LOGIN_FAILED: "USER_LOGIN_FAILED",
  LOGOUT: "LOGOUT",
  LOGOUT_FAILED: "LOGOUT_FAILED",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  CHANGE_PASSWORD_FAILED: "CHANGE_PASSWORD_FAILED",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED",
  ALL_USER: "ALL_USER",
  ALL_USER_FAILED: "ALL_USER_FAILED",
  ADD_NEW_DEVICE: "ADD_NEW_DEVICE",
  ADD_NEW_DEVICE_FAILED: "ADD_NEW_DEVICE_FAILED",
  ADD_NEW_USER: "ADD_NEW_USER",
  ADD_NEW_USER_FAILED: "ADD_NEW_USER_FAILED",
  IMPORT_BULK: "IMPORT_BULK",
  IMPORT_BULK_FAILED: "IMPORT_BULK_FAILED",
  EDIT_PROFILE: "EDIT_PROFILE",
  EDIT_PROFILE_FAILED: "EDIT_PROFILE_FAILED",
  DASHBOARD: "DASHBOARD",
  DASHBOARD_FAILED: "DASHBOARD_FAILED",
  DELETE_USER: "DELETE_USER",
  DELETE_USER_FAILED: "DELETE_USER_FAILED",
  PROFILE: "PROFILE",
  PROFILE_FAILED: "PROFILE_FAILED",
  ENERGY: "ENERGY",
  ENERGY_FAILED: "ENERGY_FAILED",
  DASHBOARD_USER_GRAPH: "DASHBOARD_USER_GRAPH",
  DASHBOARD_USER_GRAPH_FAILED: "DASHBOARD_USER_GRAPH_FAILED",
  USERBYID: "USERBYID",
  USERBYID_FAILED: "USERBYID_FAILED",
  ALL_DEVICE: "ALL_DEVICE",
  ALL_DEVICE_FAILED: "ALL_DEVICE_FAILED",
  ENERGY_SUMMARY: "ENERGY_SUMMARY",
  ENERGY_SUMMARY_FAILED: "ENERGY_SUMMARY_FAILED",
  DELETE_DEVICE: "DELETE_DEVICE",
  DELETE_DEVICE_FAILED: "DELETE_DEVICE_FAILED",
  EDIT_DEVICE: "EDIT_DEVICE",
  EDIT_DEVICE_FAILED: "EDIT_DEVICE_FAILED",
  DASHBOARD_DEVICE_GRAPH: "DASHBOARD_DEVICE_GRAPH",
  DASHBOARD_DEVICE_GRAPH_FAILED: "DASHBOARD_DEVICE_GRAPH_FAILED",
  IMPORT_BULK_USER: "IMPORT_BULK_USER",
  IMPORT_BULK_USER_FAILED: "IMPORT_BULK_USER_FAILED",
  ASSIGN_DEVICE: "ASSIGN_DEVICE",
  ASSIGN_DEVICE_FAILED: "ASSIGN_DEVICE_FAILED",
  FILTER_USER_DATA: "FILTER_USER_DATA",
  FILTER_USER_DATA_FAILED: "FILTER_USER_DATA_FAILED",
  FILTER_DEVICE_DATA: "FILTER_DEVICE_DATA",
  FILTER_DEVICE_DATA_FAILED: "FILTER_DEVICE_DATA_FAILED",
  USER_AND_DEVICES: "USER_AND_DEVICES",
  USER_AND_DEVICES_FAILED: "USER_AND_DEVICES_FAILED",
  MAP_USER: "MAP_USER",
  MAP_USER_FAILED: "MAP_USER_FAILED",
  USER_PER_DAY: "USER_PER_DAY",
  USER_PER_DAY_FAILED: "USER_PER_DAY_FAILED",
  ADD_PROJECT: "ADD_PROJECT",
  ADD_PROJECT_FAILED: "ADD_PROJECT_FAILED",
  PROJECT_LIST: "PROJECT_LIST",
  PROJECT_LIST_FAILED: "PROJECT_LIST_FAILED",
  PROJECT_EDIT: "PROJECT_EDIT",
  PROJECT_EDIT_FAILED: "PROJECT_EDIT_FAILED",
  PAI_CHART_DEVICE: "PAI_CHART_DEVICE",
  PAI_CHART_DEVICE_FAILED: "PAI_CHART_DEVICE_FAILED",
  USER_WITH_TEMPERATURE: "USER_WITH_TEMPERATURE",
  USER_WITH_TEMPERATURE_FAILED: "USER_WITH_TEMPERATURE_FAILED",
  AVERAGE_DEVICE_USAGE: "AVERAGE_DEVICE_USAGE",
  AVERAGE_DEVICE_USAGE_FAILED: "AVERAGE_DEVICE_USAGE_FAILED",
};
