import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { importBulk } from "../redux/actions/userAuth";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useEffect } from "react";

const ImportBulk = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state);
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState(null);
  console.log("your selected File---", selectedFile);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { name: "" } });
  const onSubmit = () => {
    if (!selectedFile) {
      toast.error("Select a CSV file", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);

      dispatch(importBulk(formData));
      console.log("Submitted Data:", formData);
    } else {
      console.log("No file selected");
    }
  };
  useEffect(() => {
    if (status.importBulk?.successData?.data !== "") {
      if (status.importBulk?.successData?.status === 201) {
        toast.success("Devices Import Success ", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          let path = `/dashboard/AllDevices`;
          setSelectedFile(null);
          navigate(path);
        }, 2500);
      }
      if (
        status.importBulk?.errorData?.response?.data?.error ===
        'failed to add device: pq: duplicate key value violates unique constraint "devices_pkey"'
      ) {
        toast.error(
          "This device ID is already exist, please use a different device ID",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          }
        );
      }
    }
  }, [status]);
  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            All Devices
            <span>
              <img alt="img" src="../image/dish.svg"></img> / All Devices /
              Import Bulk Devices
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap inputwrap">
          <div className="tablewrappadd">
            <div className="bothwrap">
              <div className="adduserhadd">
                <h2>Import Devices</h2>
                <p>Bulk Import</p>
              </div>
            </div>
            <form
              onSubmit={handleSubmit(onSubmit)}
              encType="multipart/form-data"
            >
              <div className="adduserwrap">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/upload.svg"></img>
                  </span>
                  <label htmlFor="upload-photo" className="chosefole">
                    <input
                      name="uploadphoto"
                      type="file"
                      accept=".csv"
                      onChange={(e) => setSelectedFile(e.target.files[0])}
                    />
                    <span>Supported CSV File Only</span>
                  </label>
                </div>
              </div>
              <div className="mainbtn bulk">
                <button className="save">Import</button>
              </div>
            </form>
            <div></div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ImportBulk;
