import React from "react";
import Slider from "react-slick";
const LoginSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <div className="app">
      <Slider {...settings}>
          <div className="slider">
              <img src="../image/1.png" alt="Slider_image"></img>
          </div>
          <div className="slider">
              <img src="../image/2.png" alt="Slider_image"></img>
          </div>
          <div className="slider">
              <img src="../image/3.png" alt="Slider_image"></img>
          </div>
          <div className="slider">
              <img src="../image/4.png" alt="Slider_image"></img>
          </div>
      </Slider>
    </div>
  );
}

export default LoginSlider;
