import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout, profile } from "../redux/actions/userAuth";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { jwtDecode } from "jwt-decode";
const Menu = () => {
  const profile1 = (state) => state?.profile;
  const userLogout1 = (state) => state?.userLogout;
  const status = useSelector((state) => state);
  const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const dispatch = useDispatch();
  const profile2 = useSelector(profile1);
  const userLogout = useSelector(userLogout1);
  useEffect(() => {
    setTimeout(() => {
      dispatch(profile());
    }, 2500);
  }, []);
  const logoutHandle = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (userLogout.data !== "") {
      if (userLogout.data.status === 200) {
        toast.success("🦄 Wow so easy!", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        window.sessionStorage.clear();
        let path = `/`;
        navigate(path);
        window.location.reload();
      } else {
        if (userLogout.data.response != null) {
          toast.error(userLogout.data.message, {
            position: "bottom-right",
            theme: "colored",
          });
        } else {
          toast.error(userLogout.data.message, {
            position: "bottom-right",
            theme: "colored",
          });
        }
      }
    }
  }, [userLogout]);

  useEffect(() => {
    if (profile2?.data !== "") {
      if (profile2?.data.response != null) {
        if (profile2?.data.response.status === 401) {
          toast.error(profile2?.data?.response?.data?.error, {
            position: "bottom-right",
            theme: "colored",
          });
        }
      }
      if (profile2?.data?.message === "Network Error") {
        toast.error(profile2?.data?.message, {
          position: "bottom-right",
          theme: "colored",
        });
      }
    }
  }, [profile2]);
  const isTokenExpired = (token) => {
    if (!token) return true;
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (error) {
      console.error("Error decoding token:", error);
      return true;
    }
  };
  useEffect(() => {
    if (window.sessionStorage.getItem("userToken")) {
      const token = window.sessionStorage.getItem("userToken");
      if (isTokenExpired(token)) {
        window.sessionStorage.clear();
        let path = `/`;
        navigate(path);
        window.location.reload();
      }
    } else {
      window.sessionStorage.clear();
      let path = `/`;
      navigate(path);
      window.location.reload();
    }
  }, [status, pathname]);

  const trimmedPath = pathname.slice(0, pathname.lastIndexOf("/"));
  return (
    <>
      <div className="menu">
        <div className="menulogo">
          <Link to="/dashboard">
            <img alt="logo" src="/image/logo.svg"></img>
          </Link>
        </div>
        <div className="topallmenuwrap">
          <ul className="topallmenu">
            <li>
              <Link
                className={`menu-item ${
                  pathname === "/dashboard" ? "active" : ""
                }`}
                to="/dashboard"
              >
                <img alt="" src="/image/dashboard.svg"></img> Dashboard
              </Link>
            </li>
            <li>
              <Link
                className={`menu-item ${
                  pathname === "/dashboard/Energy" ||
                  trimmedPath === "/dashboard/EnergySummary" ||
                  trimmedPath === "/dashboard/One-Day-Usages"
                    ? "active"
                    : ""
                }`}
                to="/dashboard/Energy"
              >
                <img alt="" src="/image/leaf.svg"></img> Cookstove Usage
              </Link>
            </li>
            <li>
              <Link
                className={`menu-item ${
                  pathname === "/dashboard/AllUsers" ||
                  pathname === "/dashboard/AddNewUser" ||
                  pathname === "/dashboard/ImportBulkUser" ||
                  pathname === "/dashboard/ProfileUser"
                    ? "active"
                    : ""
                }`}
                to="/dashboard/AllUsers"
              >
                <img alt="" src="/image/users.svg"></img> All Users
              </Link>
            </li>
            <li>
              <Link
                className={`menu-item ${
                  pathname === "/dashboard/AllDevices" ||
                  pathname === "/dashboard/AddNewDevice" ||
                  pathname === "/dashboard/ImportBulk" ||
                  trimmedPath === "/dashboard/EditDevice"
                    ? "active"
                    : ""
                }`}
                to="/dashboard/AllDevices"
              >
                <img alt="" src="/image/dish.svg"></img> All Devices
              </Link>
            </li>
            <li>
              <Link
                className={`menu-item ${
                  pathname === "/dashboard/DevicesLocation" ? "active" : ""
                }`}
                to="/dashboard/DevicesLocation"
              >
                <img alt="" src="/image/dish.svg"></img> Devices Location
              </Link>
            </li>
            <li>
              <Link
                className={`menu-item ${
                  pathname === "/dashboard/manage-project" ? "active" : ""
                }`}
                to="/dashboard/manage-project"
              >
                <img alt="" src="/image/project.svg"></img> Manage Project
              </Link>
            </li>
            <li>
              <Link
                className={`menu-item ${
                  pathname === "/dashboard/ChangePassword" ||
                  trimmedPath === "/dashboard/ProfileSettings"
                    ? "active"
                    : ""
                }`}
                to="/dashboard/ChangePassword"
              >
                <img alt="" src="/image/setting.svg"></img> Settings
              </Link>
            </li>
          </ul>
        </div>
        <ul className="lasttwomenu">
          <li>
            <Link onClick={logoutHandle} to="">
              <img alt="" src="/image/logout.svg"></img> Logout
            </Link>
          </li>
          <li>
            <Link to="/dashboard/ProfileUser">
              <img alt="" src="/image/profile.svg"></img>{" "}
              <span className="user-profil-name">
                {profile2?.data?.data?.name
                  ? profile2?.data?.data?.name
                  : "Jems Deo"}
              </span>
            </Link>
          </li>
        </ul>
      </div>
      <ToastContainer />
    </>
  );
};

export default Menu;
