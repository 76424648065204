import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Menu from "./Menu";
import Dashboard from "../pages/Dashboard";
import Energy from "../pages/Energy";
import EnergySummary from "../pages/EnergySummary";
import AllUsers from "../pages/AllUsers";
import AddNewUser from "../pages/AddNewUser";
import Profile from "../pages/Profile";
import AllDevices from "../pages/AllDevices";
import AddNewDevice from "../pages/AddNewDevice";
import ProfileSettings from "../pages/ProfileSettings";
import ChangePassword from "../pages/ChangePassword";
import ImportBulk from "../pages/ImportBulk";
import ImportBulkUser from "../pages/ImportBulkUsers";
import ProfileUser from "../pages/ProfileUser";
import EditDevice from "../pages/EditDevice";
import UsePerDay from "../pages/UsePerDay";
import MapLocation from "./MapLocation";
import ManageProject from "../pages/ManageProject";
import UserWithTemp from "../pages/UserWithTempData";
const PageRoute = () => {
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    document.body.classList.toggle("mob-menu", isOpen);
  }, [isOpen]);
  return (
    <>
      <div className="mainwrap">
        <div className="leftsection">
          <Menu />
        </div>
        <div className="rightsection">
          <div className="mobile-header-1">
            <div className="mob-logo">
              <div className="menulogo">
                <Link to="/dashboard">
                  <img alt="logo" src="/image/logo.svg"></img>
                </Link>
              </div>
            </div>
            <div className="mob-right-head">
              <div className="mob-btn">
                <Link to="/dashboard/AddNewUser">
                  <img alt="logo" src="/image/profile.svg"></img>
                  <span className="menu-plus"></span>
                </Link>
                <Link to="/dashboard/AddNewDevice">
                  {" "}
                  <img alt="logo" src="/image/dish.svg"></img>
                  <span className="menu-plus"></span>
                </Link>
              </div>
              <div
                className="mobile-menu-open"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <img src="/image/jam_menu.svg" />
              </div>
            </div>
          </div>
          <div
            className="open-menu-mobi"
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          ></div>
          <div className="maincontant">
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route exact path="/Energy" element={<Energy />} />
              <Route exact path="/DevicesLocation" element={<MapLocation />} />
              <Route
                exact
                path="/EnergySummary/:userId"
                element={<EnergySummary component={EnergySummary} />}
              />
              <Route exact path="/AllUsers" element={<AllUsers />} />
              <Route exact path="/AddNewUser" element={<AddNewUser />} />
              <Route
                exact
                path="/Profile/:userId"
                element={<Profile component={Profile} />}
              />
              <Route exact path="/AllDevices" element={<AllDevices />} />
              <Route exact path="/AddNewDevice" element={<AddNewDevice />} />
              <Route
                exact
                path="/ProfileSettings/:userId"
                element={<ProfileSettings component={ProfileSettings} />}
              />
              <Route
                exact
                path="/ChangePassword"
                element={<ChangePassword />}
              />
              <Route exact path="/ImportBulk" element={<ImportBulk />} />
              <Route
                exact
                path="/ImportBulkUser"
                element={<ImportBulkUser />}
              />
              <Route exact path="/ProfileUser" element={<ProfileUser />} />
              <Route
                exact
                path="/One-Day-Usages/:device_id/:startDate"
                element={<UsePerDay />}
              />
              <Route
                exact
                path="/user-with-temperature/:device_id/:startDate/:endDate"
                element={<UserWithTemp />}
              />
              <Route exact path="/manage-project" element={<ManageProject />} />
              <Route
                exact
                path="/EditDevice/:device_id"
                element={<EditDevice component={EditDevice} />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default PageRoute;
