import React from "react";
import { useState, useEffect } from "react";
import IconButton from "@mui/material/IconButton";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import { changePassword } from "../redux/actions/userAuth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ChangePassword = () => {
  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
  //   event.preventDefault();
  // };

  const dispatch = useDispatch();
  const status = useSelector((state) => state);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [newShowPassword, setNewShowPassword] = useState(false);
  const [confirmShowPassword, setConfirmShowPassword] = useState(false);
  const [enterNewPass, setEnterNewPass] = useState("");
  const [enterConPass, setEnterConPass] = useState("");
  const [passwordNotMatch, setPasswordNotMatch] = useState("");

  const oldPassword = () => setShowPassword((show) => !show);
  const NewPassword = () => setNewShowPassword((show) => !show);
  const handleClickShowPassword = () => setConfirmShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ defaultValues: { oldPassword: "", newPassword: "" } });
  const onSubmit = (data) => {
    if (data.newPassword !== data.confirmShowPassword) {
      toast.error("New Password & confirm Password are not match", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(data, "asdasds----");
      return;
    }
    if (data.newPassword === data.confirmShowPassword) {
      let apiData = {
        oldPassword: data.oldPassword,
        newPassword: data.newPassword,
      };
      dispatch(changePassword(apiData));
    }
  };

  useEffect(() => {
    if (status.userChangePassword.data !== "") {
      if (status.userChangePassword.data.status === 200) {
        toast.success("Success", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        reset();
        setEnterNewPass("");
        setEnterConPass("");
        setTimeout(() => {
          let path = `/dashboard/ChangePassword`;
          navigate(path);
          window.location.reload();
        }, 4000);
      } else {
        if (status.userChangePassword.data.response != null) {
          toast.error(
            status.userChangePassword.data.response.data.error
              ? status.userChangePassword.data.response.data.error
              : status.userChangePassword.data.response.data,
            {
              position: "bottom-right",
              theme: "colored",
            }
          );
        } else {
          toast.error(status.userChangePassword.data.message, {
            position: "bottom-right",
            theme: "colored",
          });
        }
      }
    }
  }, [status]);

  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            Settings
            <span>
              <img src="../image/setting.svg"></img> / Settings / Change
              Password
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap inputwrap">
          <div className="tablewrappadd">
            <div className="bothwrap">
              <div className="adduserhadd">
                <h2>Change Password</h2>
                <p>Update your password</p>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="adduserwrap">
                <div className="customfield change-password-page">
                  <span className="pageimage">
                    <img alt="img" src="../image/lock.svg"></img>
                  </span>
                  <FormControl variant="filled">
                    <InputLabel htmlFor="filled-adornment">
                      Current Password
                    </InputLabel>
                    <FilledInput
                      id="filled-adornment-password"
                      type={showPassword ? "text" : "password"}
                      name="oldPassword"
                      {...register("oldPassword", { required: "required" })}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={oldPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <img alt="img" src="../image/eye.svg"></img>
                            ) : (
                              <img alt="img" src="../image/eye2.svg"></img>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {errors.oldPassword &&
                    errors.oldPassword.type === "required" && (
                      <label className="error-message">
                        This is required field
                      </label>
                    )}
                </div>
                <div className="customfield change-password-page">
                  <span className="pageimage">
                    <img alt="img" src="../image/lock.svg"></img>
                  </span>
                  <FormControl variant="filled">
                    <InputLabel htmlFor="filled-adornment-password">
                      New Password
                    </InputLabel>
                    <FilledInput
                      id="filled-adornment-password-new"
                      type={newShowPassword ? "text" : "password"}
                      name="newPassword"
                      {...register("newPassword", { required: "required" })}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={NewPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {newShowPassword ? (
                              <img alt="img" src="../image/eye.svg"></img>
                            ) : (
                              <img alt="img" src="../image/eye2.svg"></img>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {errors.newPassword &&
                    errors.newPassword.type === "required" && (
                      <label className="error-message">
                        This is required field
                      </label>
                    )}
                </div>
                <div className="customfield change-password-page">
                  <span className="pageimage">
                    <img alt="img" src="../image/lock.svg"></img>
                  </span>
                  <FormControl variant="filled">
                    <InputLabel htmlFor="filled-adornment-password">
                      Confirm Password
                    </InputLabel>
                    <FilledInput
                      id="filled-adornment-password-confirm"
                      type={confirmShowPassword ? "text" : "password"}
                      name="confirmShowPassword"
                      {...register("confirmShowPassword", {
                        required: "required",
                      })}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {confirmShowPassword ? (
                              <img alt="img" src="../image/eye.svg"></img>
                            ) : (
                              <img alt="img" src="../image/eye2.svg"></img>
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {errors.confirmShowPassword &&
                    errors.confirmShowPassword.type === "required" && (
                      <label className="error-message">
                        This is required field
                      </label>
                    )}
                  {passwordNotMatch && passwordNotMatch ? (
                    <label className="error-message">{passwordNotMatch}</label>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="mainbtn">
                <button type="submit" className="save">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default ChangePassword;
