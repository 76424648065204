import { user } from "../constant";

const initialState = {
  data: "",
};

// login

export const userLoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.USER_LOGIN:
      return { ...state, data: action.payload };
    case user.USER_LOGIN_FAILED:
      return { ...state, data: action.payload };
    default:
      return state;
  }
};

// logout

export const logoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.LOGOUT:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.LOGOUT_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// change password
export const changePasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.CHANGE_PASSWORD:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.CHANGE_PASSWORD_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// forgot password

export const forgotpasswordReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.FORGOT_PASSWORD:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.FORGOT_PASSWORD_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

// all users

export const allUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.ALL_USER:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.ALL_USER_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// add new device

export const addNewDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.ADD_NEW_DEVICE:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.ADD_NEW_DEVICE_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

// add new User

export const addNewUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.ADD_NEW_USER:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.ADD_NEW_USER_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

// import bulk

export const importBulkReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.IMPORT_BULK:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.IMPORT_BULK_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

// edit prifile

export const editProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.EDIT_PROFILE:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.EDIT_PROFILE_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// dashboard

export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.DASHBOARD:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.DASHBOARD_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// delete user

export const deleteUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.DELETE_USER:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.DELETE_USER_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// profile user

export const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.PROFILE:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.PROFILE_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// energy

export const energyReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.ENERGY:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.ENERGY_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// dashboardUserGraph

export const dashboardUserGraphReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.DASHBOARD_USER_GRAPH:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.DASHBOARD_USER_GRAPH_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// dashboardDeviceGraph

export const dashboardDeviceGraphReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.DASHBOARD_DEVICE_GRAPH:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.DASHBOARD_DEVICE_GRAPH_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// user by id

export const userByIdReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.USERBYID:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.USERBYID_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// all device

export const allDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.ALL_DEVICE:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.ALL_DEVICE_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// Energy Summary

export const EnergySummaryReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.ENERGY_SUMMARY:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.ENERGY_SUMMARY_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// delete device

export const deleteDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.DELETE_DEVICE:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.DELETE_DEVICE_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// edit prifile

export const editDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.EDIT_DEVICE:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    case user.EDIT_DEVICE_FAILED:
      console.log(action.payload, action.type);
      return { ...state, data: action.payload };
    default:
      return { ...state };
  }
};

// import bulk user

export const importBulkUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.IMPORT_BULK_USER:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.IMPORT_BULK_USER_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

// assing device

export const assignDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.ASSIGN_DEVICE:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.ASSIGN_DEVICE_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

// filterUserData device

export const filterUserDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.FILTER_USER_DATA:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.FILTER_USER_DATA_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

// filterDeviceData device

export const filterDeviceDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.FILTER_DEVICE_DATA:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.FILTER_DEVICE_DATA_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

// filterByDate device

export const userAndDevicesReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.USER_AND_DEVICES:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.USER_AND_DEVICES_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

// mapuser
export const mapUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.MAP_USER:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.MAP_USER_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

// user par day
export const userPerDayReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.USER_PER_DAY:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.USER_PER_DAY_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};
// PROJECT ADD
export const addProjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.ADD_PROJECT:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.ADD_PROJECT_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};
// PROJECT LIST
export const projectListReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.PROJECT_LIST:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.PROJECT_LIST_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};
// PROJECT edit
export const projectEditReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.PROJECT_EDIT:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.PROJECT_EDIT_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};
// pai chart device
export const paiChartDeviceReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.PAI_CHART_DEVICE:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.PAI_CHART_DEVICE_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

// average  device usages
export const averageDeviceUsageReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.AVERAGE_DEVICE_USAGE:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.AVERAGE_DEVICE_USAGE_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

// user with temp data
export const userWithTempDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case user.USER_WITH_TEMPERATURE:
      console.log(action.payload, action.type);
      return { ...state, successData: action.payload };
    case user.USER_WITH_TEMPERATURE_FAILED:
      console.log(action.payload, action.type);
      return { ...state, errorData: action.payload };
    default:
      return { ...state };
  }
};

export default userLoginReducer;
