import React from "react";
import { useState, useEffect } from "react";
import LoginSlider from "../component/LoginSlider";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { forgotpassword } from "../redux/actions/userAuth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state);
  console.log("@@@", status);
  const navigate = useNavigate();
  const [forgotPass, setForgotPass] = useState("");
  const [loader, setLoader] = useState(false);
  console.log("status", status);
  console.log("!!!!", forgotPass);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { emailtoid: "" } });
  if (errors === null) {
    setLoader(false);
  }

  const onSubmit = (data) => {
    setLoader(true);
    let apiData = {
      emailtoid: data.emailtoid,
    };
    dispatch(forgotpassword(apiData));
    console.log("email------", forgotpassword);
  };

  useEffect(() => {
    if (status.forgotpassword.successData !== "") {
      if (status.forgotpassword.successData?.status === 200) {
        console.log("@@@@@@@", status.forgotpassword.successData?.status);
        setTimeout(() => {
          let path = `/Successfully`;
          navigate(path);
          window.location.reload();
        }, 2500);
      } else {
        // toast.error('Email Does not exist', {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: false,
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //     });
        // if (status.forgotpassword.successData?.response != null) {
        //   toast.error('Please Enter All Field', {
        //       position: "top-right",
        //       autoClose: 5000,
        //       hideProgressBar: false,
        //       closeOnClick: true,
        //       pauseOnHover: true,
        //       draggable: true,
        //       progress: undefined,
        //       theme: "light",
        //   });
        //   setLoader(false);
        // }else{
        // toast.error(status.forgotpassword.successData?.message,{
        //     position: "bottom-right",
        //     theme: "colored",
        // });
        // setLoader(false);
        // }
      }
    }
  }, [status]);

  return (
    <div className="Loginwrap">
      <div className="container-fluid">
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-lg-6 col-md-6 col-sm-12 pl pr">
            <LoginSlider />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 pl pr">
            <div className="loginpage">
              <div className="loginpagewrap">
                <div className="logintext">
                  <Link to="/">
                    <img alt="" src="../image/farrow.svg"></img>
                  </Link>
                  <h2>Forgot Password?</h2>
                  <p>We will send you password on registered email address</p>

                  <div className="logintext">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="customfield forgotpass">
                        <span className="pageimage pageimagelogin">
                          <img alt="img" src="./image/mail.svg"></img>
                        </span>
                        <FormControl
                          sx={{ m: 0, width: "25ch" }}
                          variant="filled"
                        >
                          <InputLabel htmlFor="input">
                            Business Email
                          </InputLabel>
                          <FilledInput
                            id="outlined-error-helper-text"
                            type="text"
                            name="emailtoid"
                            //onChange={(e) => setForgotPass(e.target.value)}
                            {...register("emailtoid", {
                              required: "required",
                              pattern: /\S+@\S+\.\S+/,
                            })}
                            endAdornment={
                              <InputAdornment position="end"></InputAdornment>
                            }
                          />
                        </FormControl>
                        {errors.emailtoid &&
                          errors.emailtoid.type === "required" &&
                          toast.error("Please Enter All Field", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          })}
                        {errors.emailtoid &&
                          errors.emailtoid.type === "pattern" &&
                          toast.error("Please Enter Valid Email", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "light",
                          })}
                      </div>
                      <div className="buttonholder">
                        <button type="submit">
                          {loader && loader ? (
                            <img src="./image/loading.svg" alt="loader" />
                          ) : (
                            "Send"
                          )}
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
