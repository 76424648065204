import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Routes, Route, BrowserRouter, HashRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Auth } from "./pages/Auth";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Successfully } from "./pages/Successfully";
import PageRoute from "./component/PageRoute";
import { PrivateRoute } from "./component/privateRoute";
import { Provider } from "react-redux";
import store from "./redux/store";
import "react-toastify/dist/ReactToastify.css";
import "./css/responsive.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes history={HashRouter}>
        <Route exact path="/" element={<Auth />} />
        <Route exact path="/ForgotPassword" element={<ForgotPassword />} />
        <Route exact path="/Successfully" element={<Successfully />} />
        <Route
          exact
          path="/dashboard/*"
          element={
            <PrivateRoute>
              <PageRoute />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  </Provider>
);

reportWebVitals();
