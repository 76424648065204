import React, { useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import { useNavigate, Link } from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import TableRow from "@mui/material/TableRow";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import {
  addProject,
  projectList,
  projectEdit,
} from "../redux/actions/userAuth";
import Paper from "@mui/material/Paper";
import { useForm } from "react-hook-form";
import moment from "moment";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
const ManageProject = () => {
  const status = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [projectLst, setProjectLst] = useState();
  const [projectName, setProjectName] = useState();
  const [edit, setEdit] = useState(false);
  const [projectId, setProjectId] = useState();
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
    },
  });
  const handleProject = (project) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setEdit(true);
    setProjectId(project?.projectId);
    setProjectName(project?.projectName);
  };
  const onSubmit = () => {
    if (edit) {
      let apiData = {
        projectId: projectId,
        projectName: projectName,
      };
      dispatch(projectEdit(apiData));
    } else {
      let apiData = {
        projectName: projectName,
      };
      dispatch(addProject(apiData));
    }
  };
  useEffect(() => {
    if (status.addProject?.data === "") {
      if (status.addProject?.successData?.status === 201) {
        toast.success("Project add succesfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setEdit(false);
        setProjectId("");
        setProjectName("");
      }
    }
    if (status.addProject?.errorData?.response?.status === 400) {
      toast.error(status.addProject?.errorData?.response?.data?.messageToUser, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  }, [status?.addProject]);
  useEffect(() => {
    if (status.projectEdit?.data === "") {
      if (status.projectEdit?.successData?.status === 200) {
        toast.success("Project Updated succesfully", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setEdit(false);
        setProjectId("");
        setProjectName("");
      }
    }
    if (status.projectEdit?.errorData?.response?.status === 400) {
      toast.error(
        status.projectEdit?.errorData?.response?.data?.messageToUser,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );
    }
  }, [status?.projectEdit]);
  useEffect(() => {
    dispatch(projectList());
  }, []);
  useEffect(() => {
    if (status.projectList?.successData?.data !== "") {
      if (status.projectList?.successData?.status === 200) {
        setProjectLst(status?.projectList?.successData?.data);
      }
      if (status?.projectList?.errorData?.response != null) {
        if (status?.projectList?.errorData?.response?.status === 401) {
          toast.error(status?.projectList?.errorData?.response?.data?.error, {
            position: "bottom-right",
            theme: "colored",
          });
          window.sessionStorage.clear();
          let path = `/`;
          navigate(path);
        }
      }
    }
  }, [status]);

  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            Manage Project
            <span>
              <img alt="" src="../../image/project.svg"></img> / Manage Project
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap">
          <div className="tablewrappadd filter-energy-d">
            <div className="energy-detail-header">
              <h2>{edit === true ? "Edit" : "Add"} Project</h2>
              <p>{edit === true ? "Update" : "Create"} Project </p>
            </div>
          </div>
          <div className="tablewrappadd">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="adduserwrap">
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../image/profile.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Project Name"
                    maxRows={4}
                    variant="filled"
                    name="name"
                    autoComplete="off"
                    value={projectName}
                    InputLabelProps={{
                      shrink: projectName ? true : false,
                    }}
                    onChange={(e) => setProjectName(e.target.value)}
                  />
                </div>
                <div className="customfield">
                  <div className="mainbtn" style={{ paddingTop: "5px" }}>
                    <button className="save" type="submit">
                      {edit === true ? "Update" : "Create"}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="customtabel EnergySummary1">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                ref={tableRef}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Project Id</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Created Date</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Project Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {projectLst !== null
                    ? projectLst?.map((project) => {
                        const originalDate = project?.createdAt;
                        const trimDate = moment(originalDate)
                          .toISOString()
                          .slice(0, -1);
                        const formattedDate =
                          moment(trimDate).format("MMM DD, YYYY");
                        var originalDate1 = moment(originalDate);
                        const trimTime1 = moment(originalDate1)
                          .toISOString()
                          .slice(0, -1);
                        var formattedTime = moment(trimTime1).format("hh:mm A");
                        return (
                          <TableRow
                            key={project?.projectId}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <div className="tfirst">
                                <div className="tfright">
                                  <p className="green">
                                    # {project?.projectId}
                                  </p>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>{project?.projectName}</p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>{formattedDate}</p>
                                <span>{formattedTime}</span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p className="status">
                                  {project?.isActive === false ? (
                                    <p className="redColor">Inactive</p>
                                  ) : (
                                    <p className="greenColor">Active</p>
                                  )}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>
                                  <button
                                    onClick={() => handleProject(project)}
                                  >
                                    <img alt="" src="../image/edit2.svg"></img>
                                  </button>
                                  {/* <b
                                  onClick={() =>
                                    handleDeleteUser(device.DeviceID)
                                  }
                                  >
                                    <img alt="" src="../image/delete.svg" />
                                  </b> */}
                                </p>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};
export default ManageProject;
