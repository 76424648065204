import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "../css/Map.css";
import { mapUser } from "../redux/actions/userAuth";
import { useDispatch, useSelector } from "react-redux";
import { createRoot } from "react-dom/client";
mapboxgl.accessToken =
  "pk.eyJ1IjoicmFtYW5hbmQxMjMiLCJhIjoiY2x0bzY4Y3M5MDV4cTJubWhpd2J6c3ptaSJ9.hJDzWiAw9TWX6vNmaHOsyA";
const Marker = ({ onClick, children, feature }) => {
  const _onClick = () => {
    onClick(feature);
  };

  return (
    <button
      onClick={_onClick}
      className={`marker ${
        feature?.average_duration_category === "Less than 2 hours"
          ? "small"
          : feature?.average_duration_category === "Greater than 2 hours"
          ? "big"
          : feature.average_duration_category === "2 hours"
          ? "equal"
          : ""
      }`}
    >
      {children}
    </button>
  );
};
const Map = () => {
  const mapContainerRef = useRef(null);
  const status = useSelector((state) => state?.mapUser);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(mapUser());
  }, []);
  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [78.656891, 22.113423],
      zoom: 4,
    });

    // Render custom marker components
    status?.successData?.data.forEach((feature) => {
      // Create a React ref
      const ref = React.createRef();
      // Create a new DOM node and save it to the React ref
      ref.current = document.createElement("div");
      // Render a Marker Component on our new DOM node
      // ReactDOM.render(
      //   <Marker onClick={markerClicked} feature={feature} />,
      //   ref.current
      // );
      createRoot(ref.current).render(
        <Marker onClick={markerClicked} feature={feature} />
      );
      // Create a Mapbox Marker at our new DOM node
      new mapboxgl.Marker(ref.current)
        .setLngLat(feature?.coordinates)
        .addTo(map);
    });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "bottom-left");

    // Clean up on unmount
    return () => map.remove();
  }, [status]);

  const markerClicked = (title) => {
    openPopup(title);
  };
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    device_ref: "",
    address: "",
    deviceId: "",
    coordinate: [],
  });
  const openPopup = (items) => {
    setPopupOpen(true);
    setData({
      ...data,
      name: items?.name,
      email: items?.email,
      phone: items?.phone,
      device_ref: items?.device_ref_num,
      address: items?.location_description,
      deviceId: items?.device_id,
      coordinate: items?.coordinates,
    });
  };
  const closePopup = () => {
    setPopupOpen(false);
  };
  useEffect(() => {
    document.body.classList.toggle("popup-open", isPopupOpen);
  }, [isPopupOpen]);

  return (
    <>
      <div className="map-container" ref={mapContainerRef} />
      <div className="mapcontrol">
        <div className="map-cnt">
          <p>Change hours Score</p>
        </div>
        <div className="map-dots">
          <ul>
            <li>
              <div className="map-height">
                <div className="mapDots small"></div>
              </div>
              <div className="map-num">&#8826;2</div>
            </li>
            <li>
              <div className="map-height">
                <div className="mapDots equal"></div>
              </div>
              <div className="map-num">=2</div>
            </li>
            <li>
              <div className="map-height">
                <div className="mapDots big"></div>
              </div>
              <div className="map-num">&#8827;2</div>
            </li>
          </ul>
        </div>
      </div>
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popbg">
            <div className="popup-content" id="style-1">
              <div className="popup-inner-body">
                <div className="poptext">
                  <p>
                    <b style={{ fontWeight: 600 }}>Name :</b> {data?.name}
                  </p>
                  <p>
                    <b style={{ fontWeight: 600 }}>Email :</b> {data?.email}
                  </p>
                  <p>
                    <b style={{ fontWeight: 600 }}>Phone :</b> {data?.phone}
                  </p>
                  <p>
                    <b style={{ fontWeight: 600 }}>Device Id :</b>{" "}
                    {data?.deviceId}
                  </p>
                  {/* <p>
                    <b style={{ fontWeight: 600 }}>Device Ref :</b> #
                    {data?.device_ref}
                  </p> */}
                  <p>
                    <b style={{ fontWeight: 600 }}>Address :</b> {data?.address}
                  </p>
                  <p>
                    <b style={{ fontWeight: 600 }}>Coordinates :</b> Lat-
                    {data?.coordinate[1]}, Lng-
                    {data?.coordinate[0]}
                  </p>
                </div>
              </div>
              <button className="popupCloseBtn" onClick={closePopup}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Map;
