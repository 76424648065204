import React from 'react'
import LoginSlider from '../component/LoginSlider'
import Login from '../component/Login'

export const Auth = () => {
  return (
    <div className='Loginwrap'>
      <div className='container-fluid'>
        <div className='row' style={{ minHeight: "100vh" }}>
          <div className='col-lg-6 col-md-6 col-sm-12 pl pr'>
          <LoginSlider />
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 pl pr'>
          <Login />
          </div>
        </div>
      </div>
    </div>
  )
}
