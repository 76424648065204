import React from "react";
import { userById } from "../redux/actions/userAuth";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const Profile = () => {
  const status = useSelector((state) => state);
  const dispatch = useDispatch();
  const [userprofile, setUserProfile] = useState([]);

  console.log("---222000----", userprofile);
  const { userId } = useParams();
  console.log("new profile id", userId);

  let param = window.location.href;
  const queryString = param.split("?")[1];
  if (queryString) {
    param = queryString.split("=")[1];
  } else {
    param = "";
  }
  useEffect(() => {
    const apiParams = { userId };
    dispatch(userById(apiParams, userId));
  }, [userId]);

  useEffect(() => {
    if (status.userById.data !== "") {
      if (status.userById.data.status === 200) {
        setUserProfile(status.userById.data.data);
        console.log("@@@----", setUserProfile);
      }
    }
  }, [status]);

  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            All Users
            <span>
              <img src="../../image/users.svg"></img> / All Users /{" "}
              {userprofile.name}
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap inputwrap">
          <div className="tablewrappadd">
            <div className="bothwrap">
              <div className="adduserhadd">
                <h2>{userprofile.name}</h2>
                <p>User Profile</p>
              </div>
              <div className="mainbtn">
                <Link to={`/dashboard/ProfileSettings/${userprofile.userId}`}>
                  Edit Profile
                </Link>
              </div>
            </div>

            <div className="adduserwrap">
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/profile.svg"></img>
                  </span>
                  <p>{userprofile.name}</p>
                </div>
              </div>
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/phone.svg"></img>
                  </span>
                  <p>{userprofile.phone}</p>
                </div>
              </div>
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/mail.svg"></img>
                  </span>
                  <p>{userprofile.email}</p>
                </div>
              </div>
            </div>
            <div className="adduserwrap">
              {/* <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/lock.svg"></img>
                  </span>
                  <p>{userprofile.password}</p>
                </div>
              </div> */}
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <p>{userprofile.address}</p>
                </div>
              </div>

              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <p>{userprofile.city}</p>
                </div>
              </div>
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <p>{userprofile.pincode}</p>
                </div>
              </div>
            </div>
            <div className="adduserwrap">
              {/* <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <p>{userprofile.pincode}</p>
                </div>
              </div> */}
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/flag.svg"></img>
                  </span>
                  <p>{userprofile.country}</p>
                </div>
              </div>
              <div className="selectfield">
                <div className="customfield">
                  <div className="profilewrap">
                    <span className="pageimage">
                      <img alt="img" src="../../image/users.svg"></img>
                    </span>
                    <p>{userprofile.role}</p>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="proof">Proof of identity</h4>
            <div className="adduserwrap">
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/profile.svg"></img>
                  </span>
                  <p>{userprofile.aadharcard}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
