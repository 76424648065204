import React from "react";
import LoginSlider from "../component/LoginSlider";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

export const Successfully = () => {
  return (
    <div className="Loginwrap">
      <div className="container-fluid">
        <div className="row" style={{ minHeight: "100vh" }}>
          <div className="col-lg-6 col-md-6 col-sm-12 pl pr">
            <LoginSlider />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 pl pr">
            <div className="loginpage">
              <div className="loginpagewrap">
                <div className="logintext Successfully">
                  <h2>Password reset Successfully</h2>
                  <p>Password Sent to your registered email address.</p>
                  <Button variant="contained">
                    <Link to="/">Login Now</Link>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
