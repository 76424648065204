import React from "react";
import TextField from "@mui/material/TextField";
import { allDevice, editDevice } from "../redux/actions/userAuth";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";

const EditDevice = () => {
  // const [deviceName, setdeviceName] = useState('');
  // const [deviceNumber, setdeviceNumber] = useState('');
  const dispatch = useDispatch();
  const status = useSelector((state) => state);
  const navigate = useNavigate();
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [deviceName, setDeviceName] = useState("");
  const [deviceStoveID, setDeviceStoveID] = useState("");
  const device_id = parseInt(useParams().device_id);
  const [deviceData, setDeviceData] = useState("");

  console.log("my data deviceName by id----", deviceData);

  // all device api

  useEffect(() => {
    const apiData = {};
    dispatch(allDevice(apiData));
  }, []);

  console.log("status====", status);

  useEffect(() => {
    if (device_id && status.allDevice?.data !== "") {
      if (status.allDevice?.data.status === 200) {
        const allDeviceData1 = status.allDevice.data.data;

        const nData = allDeviceData1.filter(
          (device) => device.DeviceID === device_id
        );
        setDeviceData(nData);
      }
    }
  }, [status, deletedUsers, device_id]);
  console.log(deviceData[0]?.DeviceName?.String, "alldata-------------");
  // all device api

  // edit device

  let param = window.location.href;
  const queryString = param.split("?")[1];
  if (queryString) {
    param = queryString.split("=")[1];
  } else {
    param = "";
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (Array.isArray(deviceData) && deviceData.length > 0) {
      const deviceData1 = {
        device_name:
          deviceName !== ""
            ? deviceName.toString()
            : deviceData[0]?.DeviceName?.String,
        // device_ref_num:
        //   deviceNumber.toString() !== ""
        //     ? deviceNumber.toString()
        //     : deviceData[0]?.DeviceRefNum?.String,
        stoveid:
          deviceStoveID.toString() !== ""
            ? deviceStoveID.toString()
            : deviceData[0]?.stoveID?.String,
      };
      console.log(deviceData1, "afsd--------");
      dispatch(editDevice(device_id, deviceData1));
    }
  };

  useEffect(() => {
    if (status.editDevice?.data?.status === 200) {
      toast.success("Device Edit succesfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setTimeout(() => {
        let path = `/dashboard/AllDevices`;
        navigate(path);
        window.location.reload();
      }, 2500);
    } else {
      if (
        status.editDevice?.data?.response?.data?.error === "Token is expired"
      ) {
        window.location.reload();
      } else {
        toast.error(status.editDevice?.data?.response?.data?.messageToUser, {
          position: "bottom-right",
          theme: "colored",
        });
      }
    }
  }, [status]);
  // edit device

  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            All Devices
            <span>
              <img alt="" src="../../image/dish.svg"></img> / All Devices / Edit
              Device
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap inputwrap">
          <div className="tablewrappadd">
            <div className="bothwrap">
              <div className="adduserhadd">
                <h2>Edit Device</h2>
                <p>Edit a Device</p>
              </div>
            </div>

            <form onSubmit={handleSubmit}>
              <div className="adduserwrap">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../../image/dish.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Device Name"
                    multiline
                    maxRows={4}
                    variant="filled"
                    name="Device_Name"
                    onChange={(e) =>
                      setDeviceName(e.target.value === "" ? "" : e.target.value)
                    }
                    InputLabelProps={{ shrink: deviceData ? true : false }}
                    defaultValue={deviceData[0]?.DeviceName?.String}
                  />
                </div>
                {/* <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../../image/dish.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Device Reference Number"
                    multiline
                    maxRows={4}
                    variant="filled"
                    name="Device_Number"
                    onChange={(e) =>
                      setDeviceNumber(
                        e.target.value === "" ? "" : e.target.value
                      )
                    }
                    InputLabelProps={{ shrink: deviceData ? true : false }}
                    defaultValue={deviceData[0]?.DeviceRefNum?.String}
                  />
                </div> */}
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../../image/dish.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Stove Id"
                    multiline
                    maxRows={4}
                    variant="filled"
                    name="Device_stove_id"
                    onChange={(e) =>
                      setDeviceStoveID(
                        e.target.value === "" ? "" : e.target.value
                      )
                    }
                    InputLabelProps={{ shrink: deviceData ? true : false }}
                    defaultValue={deviceData[0]?.stoveID?.String}
                  />
                </div>
              </div>
              <div className="mainbtn">
                <button>Update</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default EditDevice;
