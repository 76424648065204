import React from "react";
import TextField from "@mui/material/TextField";
import { addNewDevice } from "../redux/actions/userAuth";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";

const AddNewDevice = () => {
  const [deviceName, setdeviceName] = useState("");
  const [deviceNumber, setdeviceNumber] = useState();
  const [stoveid, setStoveid] = useState("");
  const dispatch = useDispatch();
  const status = useSelector((state) => state);
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  console.log("status", status);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { deviceName: "", deviceRefNum: "", stoveid: "" },
  });
  if (errors === null) {
    setLoader(false);
  }
  const onSubmit = (data) => {
    setLoader(true);
    let apiData = {
      deviceName: deviceName,
      deviceId: parseInt(deviceNumber),
      stoveid: stoveid,
    };
    dispatch(addNewDevice(apiData));
  };

  useEffect(() => {
    if (status.addNewDevice?.successData?.data !== "") {
      if (status.addNewDevice?.successData?.status === 201) {
        setTimeout(() => {
          let path = `/dashboard/AddNewDevice`;
          navigate(path);
          window.location.reload();
        }, 2500);
        toast.success("Device Added Successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        if (
          status.addNewDevice?.errorData?.response?.data?.statusCode === 400
        ) {
          toast.error(
            status.addNewDevice?.errorData?.response?.data?.messageToUser,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setLoader(false);
        }
        if (
          status.addNewDevice?.errorData?.response?.data?.error ===
          'failed to add device: pq: duplicate key value violates unique constraint "devices_pkey"'
        ) {
          toast.error(
            "This device ID is already exist, please use a different device ID",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            }
          );
          setLoader(false);
        }
      }
    }
  }, [status]);

  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            All Devices
            <span>
              <img src="../image/dish.svg"></img> / All Devices / Add New Device
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap inputwrap">
          <div className="tablewrappadd">
            <div className="bothwrap">
              <div className="adduserhadd">
                <h2>Add New Device</h2>
                <p>Create a Device</p>
              </div>
              <div className="mainbtn">
                <Link to="/dashboard/ImportBulk" className="bulkBTn">
                  Import Bulk Devices
                </Link>
                <Link to="../download/import-devices.csv" className="csvFile">
                  Download CSV format
                </Link>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="adduserwrap">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/dish.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Device Name"
                    multiline
                    maxRows={4}
                    variant="filled"
                    name="Device_Name"
                    onChange={(e) => setdeviceName(e.target.value)}
                  />
                </div>
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../image/dish.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Device Id"
                    type="number"
                    maxRows={4}
                    variant="filled"
                    name="Device_Number"
                    onChange={(e) => setdeviceNumber(e.target.value)}
                  />
                </div>
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/dish.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Stove Id"
                    multiline
                    maxRows={4}
                    variant="filled"
                    name="stoveid"
                    onChange={(e) => setStoveid(e.target.value)}
                  />
                </div>
              </div>
              <div className="mainbtn">
                <button>Save</button>
              </div>
            </form>
            {/* <div className="mainbtn">
                    <Link className="save" to="#">Save</Link>
                </div> */}
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default AddNewDevice;
