import { combineReducers } from "redux";
import {
  userLoginReducer,
  logoutReducer,
  changePasswordReducer,
  forgotpasswordReducer,
  allUserReducer,
  addNewDeviceReducer,
  addNewUserReducer,
  importBulkReducer,
  editProfileReducer,
  dashboardReducer,
  deleteUserReducer,
  profileReducer,
  energyReducer,
  dashboardUserGraphReducer,
  userByIdReducer,
  allDeviceReducer,
  EnergySummaryReducer,
  deleteDeviceReducer,
  editDeviceReducer,
  dashboardDeviceGraphReducer,
  importBulkUserReducer,
  assignDeviceReducer,
  filterUserDataReducer,
  filterDeviceDataReducer,
  userAndDevicesReducer,
  mapUserReducer,
  userPerDayReducer,
  addProjectReducer,
  projectListReducer,
  projectEditReducer,
  paiChartDeviceReducer,
  userWithTempDataReducer,
  averageDeviceUsageReducer,
} from "./authReducer";

const RootReducer = combineReducers({
  login: userLoginReducer,
  userLogout: logoutReducer,
  userChangePassword: changePasswordReducer,
  forgotpassword: forgotpasswordReducer,
  allUser: allUserReducer,
  addNewDevice: addNewDeviceReducer,
  addNewUser: addNewUserReducer,
  importBulk: importBulkReducer,
  editProfile: editProfileReducer,
  dashboard: dashboardReducer,
  deleteUser: deleteUserReducer,
  profile: profileReducer,
  energy: energyReducer,
  dashboardUserGraph: dashboardUserGraphReducer,
  userById: userByIdReducer,
  allDevice: allDeviceReducer,
  Energysummary: EnergySummaryReducer,
  deleteDevice: deleteDeviceReducer,
  editDevice: editDeviceReducer,
  dashboardDeviceGraph: dashboardDeviceGraphReducer,
  importBulkUser: importBulkUserReducer,
  assignDevice: assignDeviceReducer,
  filterUserData: filterUserDataReducer,
  filterDeviceData: filterDeviceDataReducer,
  userAndDevices: userAndDevicesReducer,
  mapUser: mapUserReducer,
  userPerDay: userPerDayReducer,
  addProject: addProjectReducer,
  projectList: projectListReducer,
  projectEdit: projectEditReducer,
  paiChartDevice: paiChartDeviceReducer,
  userWithTempData: userWithTempDataReducer,
  averageDeviceUsage: averageDeviceUsageReducer,
});

export default RootReducer;
